const Links = {
    interior: [
        {
            text: "Instagram (@arleminteriordesign)",
            url: "https://www.instagram.com/arleminteriordesign/",
            newWindow: true,
        },
        {
            text: "Website",
            url: "https://arlem.com.au",
            comingSoon: true,
        },
        {
            text: "Message us",
            url: "/message-us",
        },
    ],
    label: [
        {
            text: "Instagram (@arlemthelabel)",
            url: "https://www.instagram.com/arlemthelabel/",
            newWindow: true,
        },
        {
            text: "Online store",
            url: "https://arlem.com.au/",
        },
    ]
}


export default Links