import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import links from '../data/links'
import Button from "../components/button"

const generateLinks = (links) => {
  return (
    links.map((link) => (
      <Button key={link.text} url={link.url} newWindow={link.newWindow}>
        <p>{link.text}</p>
        {link.comingSoon ? <p className="text-xs text-[#Cfa78e] group-hover:text-white">Coming soon</p> : <></>}
      </Button>
    ))
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Arlem" description="We are an Adelaide based interior design studio, delivering a fresh approach to home transformations for contemporary residences." />
    <div className="my-8">
      <StaticImage
        src="../images/logo.jpg"
        loading="eager"
        width={300}
        quality={80}
        formats={["auto", "webp", "jpg"]}
        alt="Arlem logo"
        objectFit="fill"
      />
    </div>

    <div className="mb-20">
      <div className="flex flex-col rounded-lg shadow-md overflow-hidden mb-6">
        <div className="flex-shrink-0">
          <StaticImage
            src="../images/render.jpg"
            loading="eager"
            formats={["auto", "webp", "jpg"]}
            alt="Interior lounge"
            width={1200}
            quality={80}
            className="h-64 object-bottom"
            imgClassName="w-full object-cover"
          />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-bold text-[#Cfa78e]">
              Beautiful custom interiors
            </p>
            <p className="text-2xl font-bold text-stone-900 tracking-tight">Arlem Interior Design</p>
            <p className="mt-3 text-base text-stone-500">We are an Adelaide based interior design studio, delivering a fresh approach to home transformations for contemporary residences.</p>
          </div>
        </div>
      </div>

      {generateLinks(links.interior)}
    </div>

    <div className="mb-20">

      <div className="flex flex-col rounded-lg shadow-md overflow-hidden mb-6">
        <div className="flex-shrink-0">
          <StaticImage
            src="../images/cushion-cover.jpg"
            loading="eager"
            formats={["auto", "webp", "jpg"]}
            alt="Interior lounge"
            width={1200}
            quality={80}
            className="h-64 object-bottom"
            imgClassName="w-full object-cover"
          />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-bold text-[#Cfa78e]">
              Our online store
            </p>
            <p className="text-2xl font-bold text-stone-900 tracking-tight">Arlem The Label</p>
            <p className="mt-3 text-base text-stone-500">
              Add a touch of luxury to your home with our designer cushions.
            </p>
          </div>
        </div>
      </div>
      {generateLinks(links.label)}
    </div>
  </Layout>
)

export default IndexPage
